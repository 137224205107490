export const mainNavbarLinks = [
  {
    link: '/',
    label: 'Cryptocurrencies',
  },
  {
    link: '/exchanges',
    label: 'Exchanges',
  },
  {
    link: '/learn',
    label: 'Learn Crypto',
  },
];
